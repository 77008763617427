import Api from "@/services/Api";

export default {
  list() {
    return Api().get("/api/smsProviders");
  },
  updateUsers(params) {
    return Api().put("/api/smsProviders/updateUsers", params);
  }
};
