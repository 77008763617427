<template>
  <div class="animated fadeIn container">
    <h1>발송 결과</h1>
    <div class="mt-4 adm-filter-container" v-if="this.$store.state.isAdmin">
      <b-input-group style="max-width:400px;display:flex;gap:10px;align-items: center;">
        <select class="form-control" v-model="searchType">
          <option value="message">메시지</option>
          <option value="user" selected>회원명 또는 이메일</option>
        </select>
        <b-form-input type="text" class="form-control ml-1" placeholder="검색어" v-model="searchKeyword"
          @keydown.native="searchEmailInputKeyDown" />
        <b-btn variant="primary" class="fl ml-1" @click.prevent="clickSearch">검색</b-btn>
      </b-input-group>
      <b-btn-group size="sm" class="btn-wrap">
        <button class="btn" :class="{ 'btn-primary': !providerId, 'btn-outline-primary': providerId }"
          @click="updateProviderId(0)">
          전체
        </button>
        <button class="btn"
          :class="{ 'btn-primary': providerId == provider.id, 'btn-outline-primary': providerId != provider.id }"
          v-for="provider in providers" @click="updateProviderId(provider.id)" :key="provider.id">
          {{ provider.title }}
        </button>
      </b-btn-group>
    </div>
    <b-input-group class="mt-4" v-show="!this.$store.state.isAdmin">
      <b-btn class="mr-2 fl" v-if="user ? user.canDownload : null" variant="primary" @click="requestAllAttachments">발송결과
        전체 내려받기</b-btn>
      <b-btn variant="danger" class="fl" @click="deleteAllSmsRequests">발송결과 전체 삭제</b-btn>
      <b-btn variant="warning" class="fl ml-2" @click="deleteSmsRequests">선택된 발송결과 삭제</b-btn>
    </b-input-group>
    <p v-if="!isLoading" class="mt-4">
      총 <span class="color-skyblue font-weight-bold">{{ totalCount | numberFormat }}</span> 건의 내용이 검색되었습니다
    </p>
    <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size"
      style="margin:50px 0"></pulse-loader>
    <table class="border-table hover-table" v-show="!isLoading">
      <thead>
        <tr>
          <td v-show="!$store.state.isAdmin" width="60px" class="text-center">선택</td>
          <!-- <td width="80px" v-show="this.$store.state.isAdmin">고유번호</td> -->
          <td width="80px" v-show="this.$store.state.isAdmin">이름</td>
          <td width="120px">발송시각</td>
          <td width="30px"></td>
          <td>내용</td>
          <td class="text-center" width="300px">상태</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="(item, index) in items">
          <td v-show="!$store.state.isAdmin" class="text-center">
            <input type="checkbox" v-model="selectedRequests[index]" />
          </td>
          <td v-show="$store.state.isAdmin" @click="pushAdminUser(item.userId)">
            <span class="mr-3 text-center">
              {{ item.username }}
            </span>
          </td>
          <td @click="pushSmsRequest(item.id)">
            <span class="mr-3">{{ item.createdAt | dateFormat }}</span>
          </td>
          <td>
            <button class="hover-btn">
              <img src="/img/i_copy.svg" @click="copyToClipboard(item.message)" class="i-copy" v-b-tooltip.hover
                title="복사하기">
            </button>
          </td>
          <td @click="pushSmsRequest(item.id)">
            <span class="hover-underline" v-html="domPurify(item.message)"></span>
          </td>
          <td @click="pushSmsRequest(item.id)">
            <span v-if="successCount(item) == item.totalCount" class="float-right mr-2">
              처리완료
            </span>
            <span v-else class="float-right mr-2">
              발송
              <span class="color-skyblue">
                {{ item.totalCount }}
              </span> / 성공
              <span class="color-skyblue">
                {{ item.deliveredCount }}
              </span> / 대기
              <span class="color-skyblue">
                {{ item.waitCount }}
              </span> / 실패
              <span class="color-skyblue">
                {{ item.deliveryFailedCount }}
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <b-pagination v-show="!isLoading" align="center" :total-rows="totalCount" v-model="currentPage" :per-page="10"
      :limit="10" class="mt-4 " @change="changePage"></b-pagination>
  </div>
</template>

<style>
.adm-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-wrap {
  display: flex;
  gap: 5px;
}

.hover-underline:hover {
  text-decoration: underline;
}
</style>

<script>
import SmsService from '@/services/SmsService'
import SmsProviderService from '@/services/SmsProviderService'
import moment from 'moment'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import { mapGetters } from 'vuex'
import AnimateNumber from '../components/AnimateNumber.vue'

export default {
  name: 'smsSend',
  components: {
    PulseLoader,
    AnimateNumber
  },
  data: () => {
    return {
      isLoading: true,
      loaderStyle: {
        color: '#ccc',
        size: '8px',
      },
      items: [],
      limit: 10,
      totalCount: Number.MAX_SAFE_INTEGER,
      currentPage: null,
      userId: null,
      searchType: 'message',
      searchKeyword: '',
      selectedRequests: [],
      providerId: 0,
      providers: [],
      interval: null,
      controller: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  mounted() {
    document.body.classList.add('bg-white')

    this.searchType = this.$route.query.searchType || 'message'
    this.searchKeyword = this.$route.query.searchKeyword
    this.userId = this.$route.query.userId
    this.currentPage = parseInt(this.$route.query.page) || 1
    this.providerId = parseInt(this.$route.query.providerId) || 0

    this.getSmsProviders()
    this.getRequests()
  },
  beforeDestroy() {
    clearTimeout(this.interval);
  },
  methods: {
    async getRequests() {
      try {
        if (this.controller) {
          this.controller.abort();
        }

        clearTimeout(this.interval);
        if (document.visibilityState !== 'visible') {
          return
        }

        var params = {
          searchType: this.$route.query.searchType,
          searchKeyword: this.$route.query.searchKeyword,
          page: this.$route.query.page || this.currentPage,
          limit: this.limit,
        }

        if (this.userId) {
          params.userId = this.userId
        }

        if (this.providerId) {
          params.providerId = this.providerId
        }

        const controller = new AbortController();
        const signal = controller.signal;

        this.controller = controller

        // this.isLoading = true
        const response = await SmsService.getRequests(params, signal)
        this.isLoading = false
        if (response.status === 200 && response.data) {
          this.items = response.data.list
          this.totalCount = response.data.totalCount
          this.currentPage = params.page
        }
      }
      catch (e) {
        // do nothing
        console.log(e)
      }
      finally {
        this.interval = setTimeout(() => {
          this.getRequests()
        }, 3000)
      }
    },
    async deleteSmsRequests() {
      var deleteItems = []
      for (var i = 0, l = this.items.length; i < l; i++) {
        const request = this.selectedRequests[i]
        if (request === true) {
          deleteItems.push(this.items[i].id)
        }
      }
      if (deleteItems.length == 0) {
        alert('삭제할 발송을 선택해주세요')
        return
      }

      if (!confirm('삭제한 발송 결과는 복구할 수 없습니다. 선택된 발송결과를 삭제하시겠습니까?')) {
        return
      }

      await SmsService.deleteSmsRequests({ ids: deleteItems.join(',') })
      this.currentPage = 1
      this.getRequests()
    },
    async getSmsProviders() {
      const response = await SmsProviderService.list()
      if (response.status == 200) {
        this.providers = response.data.list
      }
    },
    async deleteAllSmsRequests() {
      if (!confirm('삭제한 발송 결과는 복구할 수 없습니다. 발송결과를 모두 삭제하시겠습니까?')) {
        return
      }

      await SmsService.deleteSmsRequests()
      this.currentPage = 1
      this.getRequests()
    },
    paidMoney: function (count) {
      return parseFloat(count) * 19.8
    },
    pushSmsRequest: function (requestId) {
      this.$router.push({
        path: `/smsRequests/${requestId.toString()}`,
      })
    },
    pushAdminUser: function (userId) {
      this.$router.push({
        path: `/admin/users/${userId}`,
      })
    },
    successCount: function (item) {
      if (!item || !item.unit) {
        return 0
      }
      var count = 0
      var units = item.unit
      for (var i = 0; i < units.length; i++) {
        const unit = units[i]
        if (unit.status == 3) count += unit.count
      }
      return count
    },
    requestAllAttachments: function () {
      SmsService.getAllAttachment(this.user.id).then(response => {
        const url = window.URL.createObjectURL(new Blob(['\ufeff', response.data]))
        const link = document.createElement('a')
        link.href = url

        link.setAttribute('download', 'results_' + moment().format('YYYYMMDDHHmm') + '.csv')
        document.body.appendChild(link)
        link.click()
      })
    },
    changePage(page) {
      var query = {
        page: page,
      }

      if (this.userId) {
        query.userId = this.userId
      }

      if (this.searchType) {
        query.searchType = this.searchType
      }

      if (this.searchKeyword) {
        query.searchKeyword = this.searchKeyword
      }

      if (this.providerId) {
        query.providerId = this.providerId
      }

      this.selectedRequests = []

      this.$router.push({
        path: '/smsRequests',
        query,
      }).catch(() => { });
    },
    clickSearch() {
      this.changePage(1)
    },
    searchEmailInputKeyDown(e) {
      if (e.which === 13) {
        this.currentPage = 1
        this.getRequests()
      }
    },
    updateProviderId(providerId) {
      this.providerId = providerId
      this.changePage(1)
    },
    copyToClipboard: function (text) {
      var textArea = document.createElement("textarea");

      // 복사할 텍스트를 textarea에 설정
      textArea.value = text;

      // body에 textarea 추가
      document.body.appendChild(textArea);

      // textarea를 선택하고 복사 실행
      textArea.select();
      document.execCommand("copy");

      // body에서 textarea 제거
      document.body.removeChild(textArea);
    },
  },
  watch: {
    '$route.query.userId'() {
      this.userId = this.$route.query.userId
    },
    '$route.query.page'() {
      this.currentPage = this.$route.query.page
    },
    '$route.query.searchType'() {
      this.searchType = this.$route.query.searchType
    },
    '$route.query.searchKeyword'() {
      this.searchKeyword = this.$route.query.searchKeyword
    },
    '$route.query.providerId'() {
      this.providerId = this.$route.query.providerId
    },
    '$route.query'() {
      console.log('route query...???')
      this.isLoading = true
      this.getRequests()
    },
    items() { },
    user() { },
    searchType() { }
  },
}
</script>

<style lang="scss" scoped>
.i-copy {
  width: 15px;
  opacity: 0.3;
  cursor: pointer;
  transition: opacity .3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}
</style>